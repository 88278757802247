import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class OfficialHostApplicationsService {

  url = `${this.SettingsServ.API_BASE_URL}`;
  constructor(
    private SettingsServ: SettingsService,
    private http: HttpClient
  ) { }


  getOfficialHostApplicationList(params: any): Promise<any> {
    return this.http.get(`${this.url}/officialHost/application`, { params }).toPromise();
  }

  getEachOfficialHostApplicationData(id: any): Promise<any> {
    return this.http.get(`${this.url}/officialHost/application/${id}`).toPromise();
  }

  getOfficailHostStatusStats(): Promise<any> {
    return this.http.get(`${this.url}/officialHost/application/status-stats`).toPromise();
  }

  updateApplicationStatus(params: any): Promise<any> {
    return this.http.post(`${this.url}/officialHost/update-application`,params ).toPromise();
  }

  getSingleApplicationByMorId(morId: string): Promise<any> {
    return this.http.get(`${this.url}/officialHost/application/${morId}`).toPromise();

  }

  getSingleApplicationId(id: string): Promise<any> {
    return this.http.get(`${this.url}/officialHost/${id}`).toPromise();

  }

  getMorIds(): Promise<any> {
    return this.http.get(`${this.url}/officialHost/getMorIds`).toPromise();

  }

  getRegion(): Promise<any> {
    return this.http.get(`${this.url}/officialHost/region`).toPromise();

  }

  getTransactionList(params: any): Promise<any> {
    return this.http.get(`${this.url}/officialHost/transaction/list`, { params }).toPromise();
  }

}

export enum Status {
  APPROVE = 'approve',
  REJECT = 'reject',
  WAILTLIST = 'waitlist',

  APPROVED = 'approved',
  REJECTED = 'rejected',
  WAILTLISTED = 'waitlisted',
  PENDING = 'pending',

  APPROVED_NEW = 'APPROVED',
  REJECTED_NEW = 'REJECTED',
  WAILTLISTED_NEW = 'WAITLISTED',
  PENDING_NEW = 'PENDING',
}

export interface OfficialHostApplicationQuery {
  [key: string]: any;
}