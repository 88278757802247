import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../services/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) { }

  getClub(): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/clubs-api/getClub`).toPromise();
  }

  getClubMembers(params : any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/clubs-api/getClubMember`, params).toPromise();
  }

  getMemberDetails(familyId:any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/familly-api/getMemberDetail/${familyId}`).toPromise();
  }

  updateMemberStatus(familyId:any, status:any): Promise<any> {
    return this.http.get(`${this.settings.API_BASE_URL}/familly-api/updateFamilyStatus/${familyId}/${status}`).toPromise();
  }

  getFamillyMembers(params : any): Promise<any> {
    return this.http.post(`${this.settings.API_BASE_URL}/familly-api/getFamillyMember`, params).toPromise();
  }
}
